import './EconsentThankYou.less'

import React, { useEffect, useState } from 'react'

import EconsentSignedSent from '../../../../../../assets/images/econsent-signed.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { FileAnswer, fetchEconsentPdfUrl, generateEconsentPdfFile } from '../../../../../../requests'
import { DatacLoading, DatacMessage, DatacUploadFileItem } from '../../../../../common'

interface Props {
  token: string
  file: FileAnswer
  isRejected: boolean
  qrCode: string
}
export const EconsentThankYou: React.FC<Props> = ({ token, file, isRejected, qrCode }) => {
  const thankYouIntl = useScopedIntl('studies.econsent.survey.thank-you')
  const intl = useScopedIntl('')
  const [pdfFile, setPdfFile] = useState(file)
  const [fileUrl, setFileUrl] = useState<string>(null)

  useEffect(() => {
    if (!pdfFile?.id) {
      generateEconsentPdfFile(
        { token, qrCode },
        {
          onSuccess: setPdfFile,
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
    }
  }, [])

  useEffect(() => {
    if (!fileUrl && pdfFile?.id) {
      fetchEconsentPdfUrl(
        {
          fileId: pdfFile.id,
          token,
          qrCode
        },
        {
          onSuccess: setFileUrl,
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
    }
  }, [pdfFile])

  return (
    <div className="econsent-thank-you">
      <EconsentSignedSent className="econsent-thank-you__image" />
      <h1 className="econsent-thank-you__header">
        {isRejected ? thankYouIntl('header.rejected') : thankYouIntl('header')}
      </h1>
      <DatacLoading isLoading={!fileUrl}>
        <DatacUploadFileItem
          className="econsent-thank-you__file"
          file={pdfFile}
          canDownload
          canDelete={false}
          fileUrl={fileUrl}
        />
      </DatacLoading>
    </div>
  )
}
