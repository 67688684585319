import React from 'react'

import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { EconsentSurvey } from '../../components/studies/StudyDetailsContent/StudyEconsentContent/EconsentSurvey'
import { AccountType } from '../../requests'
import { getParsedHash } from '../../utils'

const IndexPage = () => {
  const [, qrCode] = getParsedHash()
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.Subject} />
      <AuthRoute accountType={AccountType.Subject} authenticatedByCode={!!qrCode}>
        <EconsentSurvey />
      </AuthRoute>
    </>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - eConsent</title>
