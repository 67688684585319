import './EconsentSign.less'

import { Checkbox } from 'antd'
import React, { useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { useScopedIntl } from '../../../../../../hooks'

interface Props {
  setNextDisabled: (disabled: boolean) => void
  nextDisabled: boolean
  setHandSignature: (handSignature: string) => void
  showSignatureError: boolean
  setShowSignatureError: (hasError: boolean) => void
  esignatureEnabled: boolean
}

export const EconsentSign: React.FC<Props> = ({
  setNextDisabled,
  nextDisabled,
  setHandSignature,
  showSignatureError,
  setShowSignatureError,
  esignatureEnabled
}) => {
  const intlSign = useScopedIntl('studies.econsent.survey.sign')
  const padRef = useRef<SignatureCanvas>(null)

  useEffect(() => {
    setHandSignature('')
  }, [])

  const handleClickClear = () => {
    padRef.current.clear()
    setHandSignature('')
  }

  const onStrokeEnd = () => {
    setHandSignature(padRef.current.toDataURL('image/svg+xml'))
  }

  const onStrokeBegin = () => {
    setShowSignatureError(false)
  }

  return (
    <div className="econsent-sign">
      <h1 className="econsent-sign__header">{intlSign(esignatureEnabled ? 'esignature.header' : 'header')}</h1>
      <div className="econsent-sign__content">
        {esignatureEnabled ? (
          <p>{intlSign('esignature.description')}</p>
        ) : (
          <>
            <Checkbox defaultChecked={!nextDisabled} onChange={e => setNextDisabled(!e.target.checked)}>
              <p>{intlSign('description')}</p>
              <p>{intlSign('additional_info')}</p>
            </Checkbox>
            <SignatureCanvas
              ref={padRef}
              penColor="black"
              canvasProps={{
                className: `econsent-sign__canvas ${showSignatureError ? 'econsent-sign__canvas--error' : ''}`
              }}
              onBegin={onStrokeBegin}
              onEnd={onStrokeEnd}
            />
            <div className="econsent-sign__clear" onClick={handleClickClear}>
              {intlSign('clear')}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
