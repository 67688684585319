import './EconsentPdf.less'

import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { fetchEconsentPdfUrl, generateEconsentPdfFile } from '../../../../../../requests'
import { DatacMessage } from '../../../../../common'
import { StudyEconsentPdf } from '../../StudyEconsentPdf'

interface Props {
  token: string
  setNextDisabled: (disabled: boolean) => void
}

export const EconsentPdf: React.FC<Props> = ({ token, setNextDisabled }) => {
  const intlPdf = useScopedIntl('studies.econsent.pdf')
  const [pdfFile, setPdfFile] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [divHeight, setDivHeight] = useState(0)

  const getEconsentPdfUrl = (fileId: string) =>
    fetchEconsentPdfUrl(
      { fileId, token },
      {
        onSuccess: (pdfFile: string) => {
          setPdfFile(pdfFile)
          setIsLoading(false)
        },
        onRequestError: () => {
          DatacMessage.error(intlPdf('error.title'), intlPdf('error.content'))
          setIsLoading(false)
        }
      }
    )

  const calculateHeight = () => {
    // we must calculate survey pdf height here, to use correct mobile device client height from 100vh component

    const clientHeight = document.getElementById('100vh-div').clientHeight // height of 100vh component
    const _26rem = 26 * parseFloat(getComputedStyle(document.documentElement).fontSize)
    setDivHeight(clientHeight - _26rem)
  }

  useEffect(() => {
    generateEconsentPdfFile(
      { token },
      {
        onSuccess: file => getEconsentPdfUrl(file.id),
        onRequestError: () => {
          DatacMessage.error(intlPdf('unknown_error.title'), intlPdf('unknown_error.content'))
          setIsLoading(false)
        }
      }
    )
    calculateHeight()
    window.addEventListener('resize', calculateHeight)
    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
  }, [])

  return (
    <div className="econsent-pdf" style={{ height: `${divHeight}px` }}>
      <StudyEconsentPdf
        isLoading={isLoading}
        pdfFile={pdfFile}
        setWholePdfWasChecked={value => setNextDisabled(!value)}
      />
    </div>
  )
}
