import './StudyEconsentPdf.less'

import React, { useContext, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'

import { useScopedIntl, useVisibility } from '../../../../../hooks'
import { proxyMinioToLocalhost } from '../../../../../utils'
import { DatacIcon, DatacLoading } from '../../../../common'
import { StudyDetailsContext } from '../../StudyDetailsContext'

interface Props {
  pdfFile: string
  isLoading: boolean
  setWholePdfWasChecked: (checked: boolean) => void
  showScroll?: boolean
}

export const StudyEconsentPdf: React.FC<Props> = ({ setWholePdfWasChecked, pdfFile, isLoading, showScroll = true }) => {
  const { study } = useContext(StudyDetailsContext)
  const intlPdf = useScopedIntl('studies.econsent.pdf')
  const [numPages, setNumPages] = useState<number>(null)
  const [isBottomDivVisible, bottomDiv] = useVisibility<HTMLDivElement>(100)
  const [scrolledDown, setScrolledDown] = useState(false)
  const [scale, setScale] = useState(1)
  const [pdfData, setPdfData] = useState<string | ArrayBuffer>()

  useEffect(() => {
    const initialWindowWidth = window.innerWidth
    if (initialWindowWidth < 620) {
      setScale(initialWindowWidth / 620)
    }
  }, [])

  // TODO: this is temporarily solution, as current react-pdf has problem with webpack.
  // We need this react-pdf version for e-signatures. We should remove this when react-pdf will be updated
  useEffect(() => {
    if (pdfjs?.GlobalWorkerOptions) {
      pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
    }
  }, [pdfjs?.GlobalWorkerOptions])

  useEffect(() => {
    if (isBottomDivVisible || study?.esignatureEnabled) {
      setWholePdfWasChecked(true)
      setScrolledDown(true)
    }
  }, [isBottomDivVisible, study])

  useEffect(() => {
    if (pdfFile) {
      const reader = new window.FileReader()
      fetch(proxyMinioToLocalhost(pdfFile))
        .then(response => response.blob())
        .then(blob => {
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            setPdfData(reader.result)
          }
        })
    }
  }, [pdfFile])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => setNumPages(numPages)

  return (
    <DatacLoading isLoading={isLoading}>
      {!!pdfData && (
        <>
          <div className="study-econsent-pdf">
            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              {[...Array(numPages)].map((_, index) => (
                <Page
                  pageNumber={index + 1}
                  key={index}
                  className="study-econsent-pdf__page"
                  scale={scale}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
            </Document>
            <div className="study-econsent-pdf__bottom" ref={bottomDiv} />
          </div>
          {showScroll && !scrolledDown && (
            <div className="study-econsent-pdf__see-whole-page">
              <DatacIcon name="alertCircle" raw className="study-econsent-pdf__see-whole-page-icon" />{' '}
              {intlPdf('scroll_down')}
            </div>
          )}
          <div className="study-econsent-pdf__scale">
            <DatacIcon name="plus" onClick={() => setScale(prev => prev + 0.1)} />
            <DatacIcon name="minus" onClick={() => setScale(prev => Math.max(0.1, prev - 0.1))} />
          </div>
        </>
      )}
    </DatacLoading>
  )
}
