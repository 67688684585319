import React from 'react'

import { RecordVariable, Study, StudyStatus } from '../../../requests'

interface StudyDetailsContextValue {
  study: Study
  fetchStudyDetails: () => void
  checkStudyStatus: (status: StudyStatus | StudyStatus[]) => boolean
  updateStudyInclusionVariable: (variable: RecordVariable) => void
  setStudy: (study: Study) => void
  isUsingFreeTextInIds: () => boolean
}

export const StudyDetailsContext = React.createContext<StudyDetailsContextValue>({
  study: null,
  fetchStudyDetails: () => null,
  checkStudyStatus: () => false,
  updateStudyInclusionVariable: () => null,
  setStudy: () => null,
  isUsingFreeTextInIds: () => false
})
