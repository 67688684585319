import './EconsentRejectModal.less'

import { Form, Input } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { rejectEconsentSurvey } from '../../../../../../requests'
import { validateRequired } from '../../../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal } from '../../../../../common'

export interface EconsentRejectModalProps {
  isOpened: boolean
  onClose: () => void
  onRejectionSent?: () => void
  token: string
  qrCode: string
}

export const EconsentRejectModal: React.FC<EconsentRejectModalProps> = ({
  isOpened,
  onClose,
  onRejectionSent,
  token,
  qrCode
}) => {
  const intlModal = useScopedIntl('studies.econsent.survey.reject.modal')
  const [formInstance] = Form.useForm()
  const [isSendingRejection, setIsSendingRejection] = useState(false)

  const onSubmit = ({ reason }: { reason: string }) => {
    setIsSendingRejection(true)
    rejectEconsentSurvey(
      { token, reason, qrCode },
      {
        onSuccess: () => {
          setIsSendingRejection(false)
          onClose()
          onRejectionSent()
        },
        onRequestError: () => {
          setIsSendingRejection(false)
          DatacMessage.error(intlModal('error.title'), intlModal('error.description'))
        }
      }
    )
  }

  return (
    <DatacModal
      className="econsent-reject-modal"
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={() => formInstance.submit()}
      destroyOnClose
      afterClose={() => formInstance.resetFields()}
      loading={isSendingRejection}
      submitLabel={intlModal('submit')}
    >
      <Form name="econsent-reject-form" onFinish={onSubmit} form={formInstance}>
        <div>{intlModal('description')}</div>
        <DatacFormItem
          name="reason"
          label={intlModal('reason.title')}
          validate={validateRequired(intlModal('reason.validation'))}
        >
          <Input.TextArea maxLength={130} rows={4} placeholder={intlModal('reason.placeholder')} />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
